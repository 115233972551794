
import { ref, reactive, defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';
import { LeftOutlined } from '@ant-design/icons-vue';
import { useRouter, useRoute } from 'vue-router';
import {
    dealerClaimDetail,
    supportView,
    invoiceView,
    licenseView,
} from '@/API/communication/appeal';
import PreviewModal from '../components/PreviewModal.vue';
import { b64toBlob } from '@/utils/claim/b64toBlob';

interface ProgramInfo {
    offerTypeId: string;
    offerTypeNameCn: string;
    offerTypeNameEn: string;
    programCode: string;
    programNameCn: string;
    programNameEn: string;
    siOffer: string;
}

interface VehicleInfo {
    customerName: string;
    firstSubmitDate: string;
    invoice: string;
    invoiceDate: string;
    invoicePrice: string;
    licenseDate: string;
    materialNo: string;
    model: string;
    modelYear: string;
    retailDate: string;
    retailEligibilityStatus: string;
    retailType: string;
    submissionDate: string;
    vehicleLicense: string;
    vin: string;
    dealerTrade: string | null;
    dealerTradeWholeSaleCode: string | null;
    dealerTradeWholeSaleNameCn: string | null;
}

interface CheckResultInfo {
    checkResult: string;
    finalBonus: string;
    comments: string;
}

export default defineComponent({
    components: {
        LeftOutlined,
        PreviewModal,
    },
    setup() {
        const fileType = ref('');
        const base64code = ref('');
        const previewVisible = ref<boolean>(false);
        const showPreview = (
            supportingDocId: string,
            supportDocType: string
        ) => {
            const params = {
                supportingDocId: supportingDocId,
                supportingDocType: supportDocType,
            };
            supportView(params).then((res: any) => {
                if (res.ext === 'jpg' || res.ext === 'png') {
                    base64code.value =
                        'data:image/jpg;base64,' + res.base64code;
                } else if (res.ext === 'pdf') {
                    base64code.value = URL.createObjectURL(
                        b64toBlob(res.base64code, 'application/pdf')
                    );
                }
                fileType.value = res.ext;
                previewVisible.value = true;
            });
        };

        const closePreview = () => {
            previewVisible.value = false;
        };

        const checkResultInfoColumns = [
            {
                title: '检查结果',
                width: 200,
                dataIndex: 'checkResult',
            },
            {
                title: '金额',
                width: 200,
                dataIndex: 'finalBonus',
            },
            {
                title: '备注',
                width: 500,
                dataIndex: 'comments',
            },
        ];
        const communicationColumns = [
            {
                title: '沟通截止日期',
                dataIndex: 'deadline',
            },
            {
                title: '本轮次结清',
                dataIndex: 'roundClosing',
            },
            {
                title: '最后一次沟通',
                dataIndex: 'lastCommunication',
            },
            {
                title: '备注',
                dataIndex: 'comments',
            },
        ];
        const operationRecordColumns = [
            {
                title: '操作',
                dataIndex: 'action',
            },
            {
                title: '操作人',
                dataIndex: 'operateBy',
            },
            {
                title: '操作时间',
                dataIndex: 'operateAt',
            },
            {
                title: '备注',
                dataIndex: 'comments',
            },
        ];

        const route = useRoute();
        const router = useRouter();
        const goBack = () => {
            router.go(-1);
        };

        const programInfo = ref<ProgramInfo>({
            offerTypeId: '',
            offerTypeNameCn: '',
            offerTypeNameEn: '',
            programCode: '',
            programNameCn: '',
            programNameEn: '',
            siOffer: '',
        });
        const vehicleInfo = ref<VehicleInfo>({
            customerName: '',
            firstSubmitDate: '',
            invoice: '',
            invoiceDate: '',
            invoicePrice: '',
            licenseDate: '',
            materialNo: '',
            model: '',
            modelYear: '',
            retailDate: '',
            retailEligibilityStatus: '',
            retailType: '',
            submissionDate: '',
            vehicleLicense: '',
            vin: '',
            dealerTrade: null,
            dealerTradeWholeSaleCode: null,
            dealerTradeWholeSaleNameCn: null
        });
        const checkResultInfo = ref<CheckResultInfo[]>([]);
        const store = useStore();
        const showPreview2 = (type: string, path: string) => {
            if (path === '') return;
            const params = {
                vinNo: vehicleInfo.value.vin,
                fileName: '',
                dealerCode: store.state.user.organization.entityCode,
            };
            let getView: (params: any) => Promise<any>;
            if (type === 'invoice') {
                getView = invoiceView;
            } else {
                getView = licenseView;
            }
            getView(params).then((res: any) => {
                const docType = (res.docType as string).toLowerCase();
                if (docType === 'jpg' || docType === 'png') {
                    base64code.value = res.downloadUrl;
                } else if (docType === 'pdf') {
                    base64code.value = URL.createObjectURL(
                        b64toBlob(res.base64, 'application/pdf')
                    );
                }
                previewVisible.value = true;
                fileType.value = docType;
            });
        };
        const communicationInfo = ref([]);
        const operationRecordInfo = ref([]);

        //Document data数据
        const documentSourceData = ref<any[]>([]);
        //Document data 表头
        const documentColumns = ref<any[]>([]);
        //Document slot
        const documentSlot = ref<any[]>([]);
        //Document 表格宽度
        const documentTableWidth = ref(950);

        const handleDocument = (data: any) => {
            const columnsArray = []; //表头
            const dataSourceColumnsArray = []; //table数据
            const slotArray = []; //插槽名称
            let tableWidth = 0;
            //生成表头,和数据对象的key
            for (let i = 0; i < data[0].length; i++) {
                if (i === 0) {
                    columnsArray.push({
                        title: '',
                        dataIndex: 'name',
                        key: 'name',
                    });
                } else {
                    columnsArray.push({
                        title: data[0][i],
                        dataIndex: 'rep' + i,
                        key: 'rep' + i,
                        slots: { customRender: 'rep' + i },
                    });
                    slotArray.push('rep' + i);
                    tableWidth += 300;
                }
            }
            //生成数据
            for (let i = 1; i < data.length; i++) {
                const onerow = data[i]; //要处理的每一行数据

                const rowData = {}; //最终生成的行对象
                rowData['name'] = onerow[0];
                for (let j = 1; j < onerow.length; j++) {
                    if (onerow[j] !== '') {
                        rowData[columnsArray[j].dataIndex] = (onerow[
                            j
                        ] as any).supportingDocReName;
                        rowData[
                            columnsArray[j].dataIndex + 'supportingDocId'
                        ] = (onerow[j] as any).supportingDocId;
                        rowData[
                            columnsArray[j].dataIndex + 'supportDocType'
                        ] = (onerow[j] as any).supportDocType;
                    } else {
                        rowData[columnsArray[j].dataIndex] = '';
                    }
                }
                dataSourceColumnsArray.push(rowData);
            }
            documentColumns.value = columnsArray;
            documentSourceData.value = dataSourceColumnsArray;
            documentSlot.value = slotArray;
            if (tableWidth > documentTableWidth.value) {
                documentTableWidth.value = tableWidth;
            }
            //handelDocement
        };

        const genName = (
            path: string,
            prefix: string,
            vinNo: string,
            ext: string
        ) => {
            if (!path) return '';
            const suffix = ext.toLowerCase();
            return `${prefix}_${vinNo}.${suffix}`;
        };

        onMounted(() => {
            const claimId = route.params.claimId as string;
            dealerClaimDetail(claimId).then((res: any) => {
                programInfo.value = res.programInfo;
                vehicleInfo.value = res.vehicleInfo;
                const tempCheckResultInfo = res.checkResultInfo;
                tempCheckResultInfo.forEach((item: any) => {
                    item.comments = item.comments?.replace(/\\n/g, ' ');
                });
                checkResultInfo.value = tempCheckResultInfo;
                communicationInfo.value = res.communicationInfo;
                operationRecordInfo.value = res.operationRecordInfo;
                handleDocument(res.claimDetailDocument);
            });
        });

        const retailEligibilityStatusMap = {
            '0000': '无',
            '1111': '系统检查中',
            '1112': '待审核',
            '1113': '修改待审核',
            '2222': '有效',
            '3333': '无效',
        };
        return {
            previewVisible,
            closePreview,
            showPreview,
            showPreview2,
            fileType,
            base64code,
            programInfo,
            vehicleInfo,
            checkResultInfoColumns,
            checkResultInfo,
            communicationColumns,
            communicationInfo,
            operationRecordColumns,
            operationRecordInfo,
            goBack,
            handleDocument,
            documentColumns,
            documentSourceData,
            documentSlot,
            documentTableWidth,
            retailEligibilityStatusMap,
            genName,
        };
    },
});
